import { Card, Col, Container, Row } from "react-bootstrap";
import Footer from "../footer/Footer";
import Header from "../header/Header";


const Home = () => <>
    <Header />
    <Container>
        <Row className="justify-content-md-center">
            <Col>
                <Card className="mt-2" style={{ width: '18rem' }}>
                    <Card.Img className="p-2" variant="top" src="https://www.shivanitextiles.com/images/1.jpg" />
                    <Card.Body>
                        <Card.Title>Pure Heavy Cotton Printed, Approx 2.50 Meters, Unstitched</Card.Title>
                        <Card.Text>
                            Daily Wear,Work,Casual Wear
                        </Card.Text>
                        <Card.Text>Rs. 690.00</Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className="mt-2" style={{ width: '18rem' }}>
                    <Card.Img className="p-2" variant="top" src="https://www.shivanitextiles.com/images/2.jpg" />
                    <Card.Body>
                        <Card.Title>Pure Cotton Printed Stitched Churidar</Card.Title>
                        <Card.Text>
                            Daily Wear,Work,Casual Wear
                        </Card.Text>
                        <Card.Text>Rs. 870.00</Card.Text>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className="mt-2" style={{ width: '18rem' }}>
                    <Card.Img className="p-2" variant="top" src="https://www.shivanitextiles.com/images/3.jpg" />
                    <Card.Body>
                        <Card.Title>Pure Heavy Cotton Printed, Approx 2.50 Meters, Unstitched</Card.Title>
                        <Card.Text>
                            Daily Wear,Work,Casual Wear
                        </Card.Text>
                        <Card.Text>Rs. 690.00</Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>

    </Container>
    <Footer />
</>;

export default Home;