import { Outlet, Link } from "react-router-dom";
import Footer from "../footer/Footer";
import Header from "../header/Header";


const Layout = () => <>
    {/* <Header />
    <Footer /> */}
    {/* <nav>
        <ul>
            <li>
                <Link to="/">Home</Link>
            </li>
        </ul>
    </nav> */}

    <Outlet />
</>;

export default Layout;