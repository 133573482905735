import { Button, Container, Form, Nav, Navbar } from "react-bootstrap";


const Header = () => <>
    <Navbar bg="light" expand="lg">
        <Container fluid>
            <Navbar.Brand href="/">
                <img
                    alt="Shivani Textiles"
                    src="https://shivanitextiles.com/logo512.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                />
                {' '}
                Shivani Textiles
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
                <Nav
                    className="me-auto my-2 my-lg-0"
                    style={{ maxHeight: '100px' }}
                    navbarScroll
                >
                    <Nav.Link href="/">MEN</Nav.Link>
                    <Nav.Link href="/">WOMEN</Nav.Link>
                    <Nav.Link href="/">KIDS</Nav.Link>
                    <Nav.Link href="/">INDIE</Nav.Link>
                    <Nav.Link href="/">HOME AND KITCHEN</Nav.Link>
                </Nav>

                <Form className="d-flex">
                    <Form.Control
                        type="search"
                        placeholder="Search"
                        className="me-2"
                        aria-label="Search"
                    />
                    <Button variant="outline-success">Search</Button>
                </Form>
            </Navbar.Collapse>
        </Container>
    </Navbar>

</>;

export default Header;