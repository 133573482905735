import { Container, Navbar } from "react-bootstrap";


const Footer = () => <>
    <Navbar bg="light" fixed="bottom">
        {/* <Container> */}
        <Navbar.Brand href="/">
            Shivani Textiles
        </Navbar.Brand>
        {/* </Container> */}
    </Navbar>
</>;

export default Footer;